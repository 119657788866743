<template>
  <div class="modal">
    <Teleport to="body">
      <modal :show="useQuickATC.state.results.length > 0" class="quick-atc">
        <template #body>
          <div class="modal__body">
            <Form
              @submit="onSubmit"
              :validation-schema="schema"
              @invalid-submit="onInvalidSubmit"
            >
              <p class="flex desktop:hidden">
                Add more products to the list below by SKU (10 max).
              </p>
              <div class="modal__additional__container">
                <v-text-input
                  id="quick-atc-input"
                  :value="data.skuList"
                  name="sku"
                  class="block desktop:hidden"
                  type="text"
                  placeholder="SKUs"
                />
                <v-text-input
                  id="quick-atc-input"
                  :value="data.skuList"
                  name="sku"
                  class="hidden desktop:block"
                  type="text"
                  placeholder="Add more products to the list below by SKU (10 max)"
                />
                <button
                  :disabled="data.loading || data.addedToCart"
                  class="btn__base btn__base--dark"
                  type="submit"
                >
                  Add
                </button>
              </div>
              <div class="modal__additional__help">
                <Field name="noProduct" as="input" class="hidden" />
                <ErrorMessage name="noProduct" />
              </div>
            </Form>
            <div>
              <ul>
                <li
                  v-for="product in useQuickATC.filteredResults"
                  :key="product.id"
                >
                  <quick-add-to-cart-item
                    :product="product"
                    :customer="props.customer"
                    @discardProduct="discardProduct"
                    @updatedProduct="updatedProduct"
                  />
                </li>
              </ul>
            </div>
          </div>
        </template>
        <template #footer>
          <div class="modal__footer">
            <div class="modal__footer__product__summary">
              <p class="accent">Number of products: {{ totalQuantity }}</p>
              <p class="accent">Total price: {{ money(totalPrice) }}</p>
            </div>
            <div class="modal__footer__action__container">
              <button
                @click.prevent="useQuickATC.state.results = []"
                class="btn__base btn__base--outline"
              >
                Close
              </button>
              <button
                @click.prevent="handleAddToCart"
                :disabled="data.loading || data.addedToCart"
                class="btn__base btn__base--dark"
              >
                <template v-if="!data.addedToCart">
                  <p class="accent">Add to cart</p>
                </template>
                <template v-else>
                  <p class="accent">Added</p>
                  <icon-check />
                </template>
              </button>
            </div>
          </div>
        </template>
      </modal>
    </Teleport>
  </div>
</template>

<script setup>
import { reactive, computed, onMounted } from 'vue';
import axios from 'axios';
import { useQuickAddToCartStore } from '../../stores/quickAddToCart';
import * as Yup from 'yup';
import { useForm, Form, Field, ErrorMessage } from 'vee-validate';
import VTextInput from './form/VTextInput.vue';
import { addItem, addItems } from '../../utils/cart';
import { useCartStore } from '../../stores/cart';
import Modal from './Modal.vue';
import QuickAddToCartItem from './QuickAddToCartItem.vue';
import IconCheck from './icons/IconCheck.vue';
import { money } from '../../utils/money';

const props = defineProps({
  customer: {
    type: Object,
    required: true,
  },
});

const useQuickATC = useQuickAddToCartStore();

const useCart = useCartStore();

const data = reactive({
  addedToCart: false,
});

const totalQuantity = computed(() => {
  return useQuickATC.filteredResults.reduce(
    (acc, item) => acc + item.quantity,
    0
  );
});

const totalPrice = computed(() => {
  return useQuickATC.filteredResults.reduce(
    (acc, item) => acc + item.subtotal,
    0
  );
});

const schema = Yup.object().shape({
  sku: Yup.string()
    .required()
    .matches(/^[A-Za-z0-9, ]+$/, 'Must be a comma separated list of SKUs'),
});

const onSubmit = async (values, actions) => {
  const config = {
    method: 'get',
    url: `search/suggest.json?q=${useQuickATC.buildSkusQuery(
      values.sku
    )}&resources[type]=product&resources[options][unavailable_products]=hide&resources[options][fields]=variants.sku`,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const res = await axios(config);
    const products = await res.data.resources.results.products;
    if (!products.length) {
      actions.setErrors({
        noProduct:
          'Product(s) not found or out of stock. Please check you are using a valid SKU.',
      });
      return;
    }
    await useQuickATC.appendProductData(
      products
    );
  } catch (err) {
    throw err;
  }
};

const onInvalidSubmit = () => {
  useQuickATC.state.disabled = true;
  setTimeout(() => {
    useQuickATC.state.disabled = false;
  }, 1500);
};

const discardProduct = (value) => {
  useQuickATC.state.loading = true;
  useQuickATC.state.results = useQuickATC.state.results.filter(
    (obj) => obj.id !== value
  );
  useQuickATC.state.loading = false;
};

const updatedProduct = (value) => {
  useQuickATC.state.loading = true;
  useQuickATC.filteredResults.forEach((product) => {
    if (product.id === value.id) {
      product.quantity = value.quantity;
      product.subtotal = value.subtotal;
    }
  });
  useQuickATC.state.loading = false;
};

const handleAddToCart = async () => {
  const items = [];
  try {
    useQuickATC.filteredResults.forEach((item) => {
      items.push({
        id: item.variants.id,
        quantity: item.quantity
      });
    });
    await addItems(items);
    await useCart.loadCartItems();
    data.addedToCart = true;
    setTimeout(() => {
      data.addedToCart = false;
      useQuickATC.state.results = [];
    }, 3000);
  } catch (err) {
    throw err;
  }
};
</script>
