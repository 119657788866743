/**
 * imports
 */
import 'vite/modulepreload-polyfill';
import { createApp, ref } from 'vue';
import { defineComponent, onMounted } from 'vue';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import QuickAddToCart from '../components/render/QuickAddToCart.vue';
import TabbedSlider from '../components/render/TabbedSlider.vue';
import breakpoints from '../scripts/breakpoints';
import { useScreen, useGrid } from 'vue-screen';
import '@splidejs/vue-splide/css';

/**
 * create vue instance function
 */
const createVueApp = () => {
  const app = createApp({
    setup() {
      defineComponent({
        components: {
          Splide,
          SplideSlide,
          QuickAddToCart,
          TabbedSlider
        },
      });

      // Feature slider
      const featureSliderOptions = {
        arrows: false,
        pagination: true,
      };

      // Logo slider
      const logoSlider = ref(null);

      const logoSliderOptions = {
        arrows: false,
        pagination: false,
        perPage: 2,
        perMove: 1,
        type: 'slide',
        gap: '20px',
        mediaQuery: 'min',
        breakpoints: {
          [breakpoints.px_breakpoints['--tablet-up']]: {
            arrows: true,
            gap: '20px',
            perPage: 3,
          },
          [breakpoints.px_breakpoints['--desktop-up']]: {
            gap: '20px',
            perPage: 6,
          },
        },
      };

      const logoSliderPrevSlide = () => {
        logoSlider.value.go('<');
      }

      const logoSliderNextSlide = () => {
        logoSlider.value.go('>');
      }

      return {
        featureSliderOptions,
        logoSliderOptions,
        logoSlider,
        logoSliderPrevSlide,
        logoSliderNextSlide
      };
    },
  });

  return app;
};

/**
 * create and mount vue instance(s)
 */
const feature_slider = document.querySelector('.vue__feature__slider');
if (feature_slider) {
  createVueApp({}).component('Splide', Splide).component('SplideSlide', SplideSlide).mount(feature_slider);
}

const quick_atc = document.querySelector('.vue__quick__atc');
if (quick_atc) {
  createVueApp({}).component('QuickAddToCart', QuickAddToCart).mount(quick_atc);
}

document.querySelectorAll('.vue__tabbed__slider').forEach((tabbed_slider) => {
  if (tabbed_slider) {
    createVueApp({}).component('TabbedSlider', TabbedSlider).mount(tabbed_slider);
  }
});

const logo_slider = document.querySelector('.vue__logo__slider');
if (logo_slider) {
  createVueApp({}).component('Splide', Splide).component('SplideSlide', SplideSlide).mount(logo_slider);
}

/**
 * fixes for Shopify sections
 * 1. properly render vue components on user insert in the theme editor
 * 2. reload the current page to rerender async inserted sections with vue components
 *
 * add the 'vue' keyword to the section's wrapper classes if the section uses any vue functionality e.g.:
 * {% schema %}
 * {
 *   "class": "vue-section"
 * }
 * {% endschema %}
 */
if (window.Shopify.designMode) {
  document.addEventListener('shopify:section:load', (event) => {
    if (event.target.classList.value.includes('vue')) {
      createVueApp().mount(event.target);
    }
  });
} else if (
  !window.Shopify.designMode &&
  process.env.NODE_ENV === 'development'
) {
  new window.MutationObserver((mutationsList) => {
    mutationsList.forEach((record) => {
      const vue = Array.from(record.addedNodes).find(
        (node) => node.classList && node.classList.value.includes('vue')
      );
      if (vue) window.location.reload();
    });
  }).observe(document.body, {
    childList: true,
    subtree: true,
  });
}
