<template>
  <div class="vue__quick__atc__form__wrap">
    <Form
      @submit="onSubmit"
      :validation-schema="schema"
      @invalid-submit="onInvalidSubmit"
    >
      <div class="vue__quick__atc__header__container flex desktop:hidden">
        <p>
          To add products quickly to your cart, paste a list of SKUs separated
          by a comma (10 max).
        </p>
        <div class="vue__quick__atc__tooltip">
          <div>
            <info-circle />
            <div class="vue__quick__atc__tooltip__text">
              <p>
                For example: 72338473, 38820398, 9302380, 83828323, 19202910,
                1292012.
              </p>
              <p>
                You can specify quantities once you click the ‘Quick Add’
                button.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="vue__quick__atc__container">
        <div class="flex desktop:hidden w-full">
          <v-text-input
            :value="useQuickATC.state.skuList"
            :disabled="disabled"
            id="quick-atc-input"
            name="sku"
            type="text"
            placeholder="Paste SKUs"
          />
        </div>
        <div class="hidden desktop:flex w-full relative">
          <div class="vue__quick__atc__container__tooltip__wrap">
            <div class="vue__quick__atc__tooltip">
              <div>
                <info-circle />
                <div class="vue__quick__atc__tooltip__text">
                  <p>
                    For example: 72338473, 38820398, 9302380, 83828323,
                    19202910, 1292012.
                  </p>
                  <p>
                    You can specify quantities once you click the ‘Quick Add’
                    button.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <v-text-input
            :value="useQuickATC.state.skuList"
            :disabled="disabled"
            id="quick-atc-input"
            name="sku"
            type="text"
            placeholder="To add products quickly to your cart, paste a list of SKUs separated by a comma (10 max)"
          />
        </div>
        <button
          :disabled="disabled || useQuickATC.state.disabled"
          class="btn__base btn__base--dark submit-btn"
          type="submit"
        >
          Quick Add
        </button>
      </div>
      <div class="vue__quick__atc__no__results">
        <Field name="noProduct" as="input" class="hidden" />
        <ErrorMessage name="noProduct" />
      </div>
    </Form>
    <quick-add-to-cart-modal :customer="customer" />
  </div>
</template>

<script setup>
import { computed, reactive } from 'vue';
import { useQuickAddToCartStore } from '../../stores/quickAddToCart';
import axios from 'axios';
import * as Yup from 'yup';
import { useForm, Form, Field, ErrorMessage } from 'vee-validate';
import VTextInput from './form/VTextInput.vue';
import QuickAddToCartModal from './QuickAddToCartModal.vue';
import InfoCircle from './icons/InfoCircle.vue';

const props = defineProps({
  customer: {
    type: Object,
    required: true,
  },
});

const useQuickATC = useQuickAddToCartStore();

const data = reactive({
  noResults: false,
});

const disabled = computed(() => {
  return !props.customer.logged_in;
});

const schema = Yup.object().shape({
  sku: Yup.string()
    .required()
    .matches(/^[A-Za-z0-9, ]+$/, 'Must be a comma separated list of SKUs')
});

const onSubmit = async (values, actions) => {
  const config = {
    method: 'get',
    url: `search/suggest.json?q=${useQuickATC.buildSkusQuery(
      values.sku
    )}&resources[type]=product&resources[options][unavailable_products]=hide&resources[options][fields]=variants.sku`,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const res = await axios(config);
    const products = await res.data.resources.results.products;
    if (!products.length) {
      actions.setErrors({
        noProduct: 'Product(s) not found or out of stock. Please check you are using a valid SKU.',
      });
      return;
    }
    await useQuickATC.appendProductData(
      products
    );
  } catch (err) {
    throw err;
  }
};

const onInvalidSubmit = () => {
  useQuickATC.state.disabled = true;
  setTimeout(() => {
    useQuickATC.state.disabled = false;
  }, 1500);
};
</script>
