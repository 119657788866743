<template>
  <div v-show="data.mounted" class="vue__quick__atc__item">
    <div class="vue__quick__atc__item__title">
      <p class="accent">{{ title }}</p>
      <p class="small light">
        SKU:
        {{ props.product.variants.sku }}
      </p>
    </div>
    <div class="vue__quick__atc__item__quantity">
      <product-quantity
        :quantity="data.product.quantity"
        :max_quantity="inventoryQuantity"
        :available="true"
        source="quick-add"
        @updateQuantity="updateQuantity"
      />
    </div>
    <div class="vue__quick__atc__item__price">
      <p class="accent">{{ money(itemSubtotal) }}</p>
    </div>
    <div
      @click="discardProduct(product.id)"
      class="vue__quick__atc__item__discard"
    >
      <IconClose />
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onBeforeMount, onMounted, computed } from 'vue';
import ProductQuantity from './ProductQuantity.vue';
import { money } from '../../utils/money';
import IconClose from '../render/icons/IconClose.vue';

const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
  customer: {
    type: Object,
    required: true,
  },
});

const { title } = props.product;

const emit = defineEmits(['discardProduct', 'updatedProduct']);

const data = reactive({
  mounted: false,
  product: {
    id: props.product.id,
    quantity: 1,
    subtotal: null
  },
});

const disabled = computed(() => {
  return !props.product.available;
});

const inventoryQuantity = computed(() => {
  return props.product.variants.inventory_quantity
});

const price = computed(() => {
  return props.product.variants.price;
});

const itemSubtotal = computed(() => {
  return price.value * data.product.quantity;
});

const discardProduct = (id) => {
  emit('discardProduct', id);
};

const updateQuantity = (value) => {
  data.product.quantity = value;
  data.product.subtotal = itemSubtotal.value;
  emit('updatedProduct', data.product);
};

onMounted(() => {
  updateQuantity(1);
  data.mounted = true;
});

</script>
