import { defineStore } from 'pinia';
import { reactive, computed } from 'vue';
import axios from 'axios';

export const useQuickAddToCartStore = defineStore('quickAddToCart', () => {
  const state = reactive({
    disabled: false,
    loading: false,
    skuList: '',
    noResults: false,
    results: [],
    triggerModal: false,
    metafieldsValidated: false,
  });

  const resultCount = computed(() => {
    return state.results.length
  });

  const filteredResults = computed(() => {
    return state.results.filter((obj, index) => {
      return index === state.results.findIndex(o => obj.id === o.id);
    });
  });

  const buildSkusQuery = (skus) => {
    if (skus !== '') {
      let skusQuery = '';
      skusQuery = skus.replaceAll(',', '+OR+').replaceAll(/\s/g, '');
      return skusQuery;
    }
    return;
  };

  const submitSkuList = async (values) => {
    const config = {
      method: 'get',
      url: `search/suggest.json?q=${buildSkusQuery(
        values.sku
      )}&resources[type]=product&resources[options][unavailable_products]=hide&resources[options][fields]=variants.sku`,
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const res = await axios(config);
      const products = await res.data.resources.results.products;
      const productsAppendMetafields = await appendProductData(
        products
      );
    } catch (err) {
      throw err;
    }
  };

  const appendProductData = async (products) => {
    try {
      products.forEach(async (product) => {
        const metafieldConfig = {
          method: 'get',
          url: `/products/${product.handle}?view=append`,
          headers: {
            'Content-Type': 'application/json',
          },
        };
        product.variants = {}
        product.metafields = {}
        const res = await axios(metafieldConfig);
        const data = await res.data;
        const productVariants = data.variants
        //can't overwrite standard price - need to save this to variants.
        product.variants = productVariants
        state.results.push(product);
      });
    } catch (err) {
      throw err;
    }
    return
  };

  const onInvalidSubmit = () => {
    state.disabled = true;
    setTimeout(() => {
      state.disabled = false;
    }, 1500);
  };

  return {
    state,
    resultCount,
    filteredResults,
    buildSkusQuery,
    submitSkuList,
    appendProductData,
    onInvalidSubmit,
    // getProducts,
    // setProducts,
    // setFilters,
    // getFilteredCollection,
    // filterToggle,
    // clearFilters,
  };
});
