<template>
  <div class="tabbed__slider">
    <div class="tabbed__slider__header">
      <h2 v-html="props.title"></h2>
      <a :href="`/collections/${data.activeTab.handle}`" class="desktop:hidden">
        <p class="accent underline">View all</p>
      </a>
    </div>
    <div class="tabbed__slider__tabs">
      <div
        @click="data.displayCategoriesMenu = !data.displayCategoriesMenu"
        :class="[
          'desktop:hidden',
          'tabbed__slider__tabs__categories',
          {
            'tabbed__slider__tabs__categories--active':
              data.displayCategoriesMenu,
          },
        ]"
      >
        <p class="accent">Categories</p>
        <template v-if="!data.displayCategoriesMenu">
          <icon-plus />
        </template>
        <template v-else>
          <icon-minus />
        </template>
      </div>
      <transition-expand>
        <ul v-if="data.displayCategoriesMenu || grid.desktop">
          <li v-for="tab in props.collection_list" :key="tab.id">
            <button
              @click="updateTab(tab)"
              :class="[
                'btn__base',
                data.activeTab.handle === tab.handle
                  ? 'btn__base--dark'
                  : 'btn__base--outline-grey',
              ]"
            >
              {{ tab.title }}
            </button>
          </li>
          <li class="hidden desktop:block">
            <a :href="`/collections/${data.activeTab.handle}`">
              <p class="accent underline">View all</p>
            </a>
          </li>
        </ul>
      </transition-expand>
    </div>
    <div
      v-if="data.collectionProducts && data.collectionProducts.length"
      class="tabbed__slider__products"
    >
      <div class="product__slider">
        <product-slider
          :products="data.collectionProducts"
          :customer="props.customer"
          :options="tabbedSliderOptions"
          source="tabbed-slider"
        />
      </div>
    </div>
    <div v-else class="tabbed__slider__placeholder__grid">
      <placeholder-item
        v-for="idx in !grid.laptop ? 2 : 4"
        type="product-item"
        ratio="0.65"
        :key="idx"
      />
    </div>
  </div>
</template>

<script setup>
import { reactive, onBeforeMount } from 'vue';
import axios from 'axios';
import { useGrid } from 'vue-screen';
import breakpoints from '../../scripts/breakpoints';
import ProductSlider from './ProductSlider.vue';
import PlaceholderItem from './PlaceholderItem.vue';
import TransitionExpand from './transitions/TransitionExpand.vue';
import IconPlus from './icons/IconPlus.vue';
import IconMinus from './icons/IconMinus.vue';

const props = defineProps({
  title: {
    type: String,
    required: false,
  },
  collection_list: {
    type: Object,
    required: true,
  },
  customer: {
    type: Object,
    required: true,
  },
});

const tabbedSliderOptions = {
  arrows: false,
  pagination: true,
  perPage: 2,
  perMove: 1,
  type: 'slide',
  gap: '10px',
  mediaQuery: 'min',
  breakpoints: {
    [breakpoints.px_breakpoints['--laptop-up']]: {
      arrows: true,
      pagination: false,
      gap: '20px',
      perPage: 4,
    },
  },
};

const grid = useGrid({
  mobile: '416px',
  tablet: '640px',
  'tablet-lg': '1024px',
  laptop: '1152px',
  desktop: '1460px',
  ultrawide: '2000px',
});

const data = reactive({
  activeTab: props.collection_list[0],
  collectionProducts: null,
  displayCategoriesMenu: false,
});

const updateTab = (tab) => {
  data.collectionProducts = null;
  data.activeTab = tab;
  setTimeout(() => {
    fetchCollectionProducts();
  }, 275);
};

const fetchCollectionProducts = async () => {
  // BCSF Endpoint
  let url = new URL('https://services.mybcapps.com/bc-sf-filter/filter');

  // Request options
  let params = {
    shop: 'amalgamated-hardware-merchants.myshopify.com',
    limit: 6,
    product_available: false,
    variant_available: false,
    build_filter_tree: true,
    collection_scope: data.activeTab.id,
  };

  Object.keys(params).forEach((key) => {
    url.searchParams.append(key, params[key]);
  });

  const config = {
    method: 'get',
    url: url,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const res = await axios(config);
    data.collectionProducts = await res.data.products;
  } catch (err) {
    throw err;
  }
};

onBeforeMount(() => {
  setTimeout(() => {
    fetchCollectionProducts();
  }, 275);
});
</script>
